body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./images/shopBackground.jpg");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.checkout-container {
  padding-top: 10px;
  background-image: url("./images/shopBackground.jpg");
  display: 'flex';
  flex-direction: 'row';
}

.checkout-title {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
  margin: 0;
  color: rgba(0, 0, 0, 0.7);
}

.checkout-price {
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 95px;
  margin: 0;
  color: rgba(0, 0, 0, 1);
}

.checkout-description {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.7);
}

.checkout-product-image {
  width: 400px;
}

.checkout-button {
  background: linear-gradient(180deg, #ffffff 0%, #d9dfff 100%);
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 20px 40px rgba(23, 0, 102, 0.2);
  border-radius: 20px;

  display: flex;

  padding: 4px;
  width: 169px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 20px;

  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.checkout-button:focus {
  outline: none;
}

.checkout-button:hover {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1),
    0px 30px 60px rgba(23, 0, 102, 0.3);
  transform: translateY(-2px);
}

.checkout-button .grey-circle {
  background: rgba(68, 66, 178, 0.1);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .purple-circle {
  background: linear-gradient(200.44deg, #4316db 13.57%, #9076e7 98.38%);
  box-shadow: inset 0 0 0 0.5px rgba(0, 0, 0, 0.1);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkout-button .icon {
  width: 20px;
  height: 20px;
  margin: 0px;
}

.checkout-button .text-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 4px;
  margin: auto;
  text-align: left;
  margin-left: 16px;
}

.checkout-button .text {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 130%;
  margin: 0;
  color: rgba(0, 0, 0, 1);
  margin: 0;
}

 #footer {
    height: 100%;
    width : 100%;
    background-color: #0081cb;
    display: inline-block;
    
    }

#footer-container {
    width : 100%;
    background-color: #0081cb;
    display: inline-block;
    position: fixed;
    padding: 10px 10px 10px 10px;
    bottom: 0;
    width: 100%;
    position: relative;
}        
